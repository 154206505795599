.slidershow {
  overflow: hidden;
}

.navigation {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.bar {
  width: 50px;
  height: 10px;
  border: 2px solid purple;
  margin: 6px;
  cursor: pointer;
  transition: 0.4s;
}

.bar:hover {
  background: purple;
}

input[name="r"] {
  position: absolute;
  visibility: hidden;
}

.slides {
  width: 500%;
  height: 100%;
  display: flex;
}

.slide {
  width: 20%;
  transition: 0.6s;
}

.slide img {
  width: 100%;
  height: 100%;
}

#r1:checked ~ .s1 {
  margin-left: 0;
}

#r2:checked ~ .s1 {
  margin-left: -20%;
}

#r3:checked ~ .s1 {
  margin-left: -40%;
}

.quotes {
  line-height: 2;
  font-weight: 600;
}

.quotes li {
  border-radius: 25px;
  border-color: black;
  margin-bottom: 5px;
}

.quotes li q {
  border-color: black;
  border-style: groove;
  background-color: moccasin;
}

@media only screen and (max-width: 865px) {
  .slides {
    display: none;
  }
  .navigation {
    display: none;
  }
  .linkedin-quotes {
    display: block;
  }
  .slide img {
    display: none;
  }
}
