.navyUL li {
  display: inline;
}

.headnav li a {
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.navyUL {
  position: fixed;
  width: 100%;
  background-color: mediumspringgreen;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-size: 22px;
  font-family: sans-serif;
  z-index: 5;
}

.navyUL a:hover {
  color: moccasin;
  background-color: black;
}

.navyUL li {
  display: inline;
  text-align: -webkit-match-parent;
}

.navyUL a {
  display: inline-block;
  text-decoration: none;
  text-decoration-line: none;
  text-decoration-style: initial;
  text-decoration-color: initial;
  text-decoration-skip-ink: auto;
  color: inherit;
  position: relative;
  transition: var(--transition);
}

#container {
  width: 100%;
  height: 100vh;
  scroll-behavior: smooth;
  overflow-y: scroll;
}

#container #bio {
  position: relative;
  width: 100%;
}

#container #project {
  position: relative;
  width: 100%;
  height: 100%;
}

#container #connect {
  position: relative;
  width: 100%;
  height: 100%;
}

#connect {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: sans-serif;
  line-height: 2;
  letter-spacing: 3px;
  margin-top: 100px;
}

#connect button {
  border-radius: 30px;
  border-color: palegreen;
  cursor: pointer;
}

#connect button:hover {
  background-color: moccasin;
}

#connect button a {
  color: black;
}
#bio {
  position: relative;
  display: flex;
  justify-content: center;
  font-family: sans-serif;
  flex-direction: column;
  line-height: 2px;
}

.intro-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-style: solid;
  border-color: moccasin;
  line-height: 0.8;
  margin: 100px 215px 100px 215px;
  height: fit-content;
  width: 60%;
}

.intro-div h1 {
  text-align: center;
}

#bio h1 {
  font-size: 6vw;
  text-shadow: 6px 6px moccasin;
}

.intro-div p {
  line-height: 1.5;
}

.intro-div h2 {
  line-height: 1.7;
  letter-spacing: 1.5px;
}

h2 {
  font-size: 2vw;
}

p {
  font-size: 2vw;
}

#project {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  flex-direction: column;
  font-weight: 600;
  margin-top: 100px;
}

#project img {
  border-radius: 32px;
}

#smile {
  position: relative;
  height: auto;
  border-radius: 25px;
  width: 75%;
}

.skills {
  position: relative;
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  border-color: moccasin;
  border-style: outset;
  width: 80%;
  line-height: 1.7;
  font-weight: 600;
  font-size: 2vw;
}

.skills ul li {
  padding: 2vw;
}

.project-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  top: 105px;
  margin-bottom: 100px;
}

.pj {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  background: moccasin;
  color: mediumspringgreen;
  border-radius: 50%;
  border-color: moccasin;
  animation: pulse 3s linear infinite;
  cursor: pointer;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(245, 153, 78, 0.952),
      0 0 0 0 rgba(245, 153, 78, 0.952);
  }
  40% {
    box-shadow: 0 0 0 50px rgba(245, 153, 78, 0.952),
      0 0 0 0 rgba(245, 153, 78, 0.952);
  }
  80% {
    box-shadow: 0 0 0 50px rgba(245, 153, 78, 0.952),
      0 0 0 30px rgba(245, 153, 78, 0.952);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(245, 153, 78, 0.952),
      0 0 0 30px rgba(245, 153, 78, 0.952);
  }
}

.gift {
  position: relative;
  width: 75%;
  animation-name: video;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  height: auto;
}

.go {
  display: none;
}

@keyframes video {
  1% {
    width: 125px;
  }
  25% {
    width: 250px;
  }
  50% {
    width: 375px;
  }
  100% {
    width: 75%;
  }
}

.live {
  display: block;
  position: relative;
  z-index: 4;
}

.github {
  display: block;
  position: relative;
  z-index: 4;
}

.lid1 {
  display: none;
}

.lid2 {
  display: none;
}

section {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.skill-sect {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.linkedin-quotes {
  display: none;
}

#sidenav {
  display: none;
}

#sidenav button {
  border-radius: 30px;
  background-color: white;
}

.language-use {
  display: flex;
  margin-top: 70px;
  list-style-type: none;
  letter-spacing: 1px;
}

.language-use li {
  padding-right: 15px;
}

@media only screen and (max-width: 1080px) {
  .skills {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
  }
}

@media only screen and (max-width: 765px) {
  #sidenav {
    display: block;
  }

  .headnav {
    display: none;
  }

  #toggle {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    cursor: pointer;
    z-index: 3;
  }

  #toggle::before {
    content: "";
    position: absolute;
    width: 28px;
    height: 8px;
    background: black;
    transform: translateY(-5px);
    transition: 0.2s;
  }

  #toggle::after {
    content: "";
    position: absolute;
    width: 28px;
    height: 8px;
    background: black;
    transform: translateY(5px);
    transition: 0.2s;
  }

  #toggle.active::before {
    transform: translateY(0px) rotate(45deg);
  }

  #toggle.active::after {
    transform: translateY(0px) rotate(-45deg);
  }

  #sidebar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: -300px;
    letter-spacing: 3px;
    line-height: 4;
    width: 35%;
    height: fit-content;
    transition: 0.5s;
    background-color: moccasin;
    list-style-type: none;
    z-index: 1;
  }

  #sidebar.active {
    right: 0%;
  }

  #sidebar a {
    font-family: sans-serif;
    font-size: larger;
    font-weight: 600;
    color: black;
    cursor: pointer;
    text-decoration: none;
  }

  #sidebar a:hover {
    color: white;
    background-color: black;
  }

  #sidebar ul {
    position: relative;
    margin-top: 50px;
  }

  #sidebar ul li {
    list-style: none;
    display: inline-block;
    width: 100%;
    padding: 10px 40px;
  }

  .intro-div {
    margin: 100px 215px 100px 10px;
  }
}

@media only screen and (max-width: 430px) {
  .intro-div h2 {
    font-size: 14px;
  }

  .intro-div p {
    font-size: 14px;
  }

  .skills {
    font-size: 14px;
  }

  .live h1 {
    font-size: 18px;
  }

  .live h2 {
    font-size: 14px;
  }

  #project-detail {
    font-size: 14px;
    margin-left: 10px;
    margin-right: 10px;
    line-height: 2;
  }

  #relate {
    font-size: 16px;
  }

  .language-use {
    display: inline;
    margin-top: 10px;
    line-height: 2;
  }
}
